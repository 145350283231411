/* react-data-grid */

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }
.rdg-cell.text-right[aria-selected=true]:not(.cell-expand-back):not([role=columnheader]) { text-align: right; }
.wide-1 { max-width: 150%; }
.wide-2 { max-width: 175%; }
[aria-selected=true].wide-2 { margin-left: -75%; }
.wide-3 { max-width: 225%; }
[aria-selected=true].wide-3 { margin-left: -125%; }
.wide-4 { max-width: 270%; }
[aria-selected=true].wide-4 { margin-left: -170%; }
.wide-5 { max-width: 360%; }
[aria-selected=true].wide-5 { margin-left: -260%; }
.wide-6 { max-width: 450%; }
[aria-selected=true].wide-6 { margin-left: -350%; }
.wide-7 { max-width: 540%; }
[aria-selected=true].wide-7 { margin-left: -440%; }
.wide-8 { max-width: 630%; }
[aria-selected=true].wide-8 { margin-left: -530%; }
.wide-9 { max-width: 800%; }
[aria-selected=true].wide-9 { margin-left: -700%; }
.wide-10 { max-width: 1000%; }
[aria-selected=true].wide-10 { margin-left: -900%; }

.rowinfo .MuiOutlinedInput-notchedOutline { border: 0 }
.rowinfo .title {
  text-align: center;
  color: var(--ion-color-primary);
  border-top: 1px solid var(--ion-color-primary);
  padding: 3px 0;
}

.rdg-text-editor {
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0px 6px 0 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);
  font-family: inherit;
  font-size: var(--rdg-font-size);
}
.rdg-text-editor:focus {
  border-color: var(--rdg-selection-color);
  outline: none;
}
.rdg-text-editor::placeholder {
  color: #999;
  opacity: 1;
}
.rdg-editor-container, .MuiAutocomplete-root {
  display: flex;
  align-items: center;
}
.rdg-summary-row .rdg-cell {
  text-align: right;
}

.highlight-name {
  color: #e0ac52;
}
.highlight-status {
  color: var(--ion-color-tertiary);
}
.status-draft {
  color: var(--ion-color-medium);
  background: linear-gradient(to left, var(--ion-color-medium), var(--ion-color-medium) 4px, transparent 4px);
}
.status-sent {
  color: var(--ion-color-secondary);
  background: linear-gradient(to left, #A4A47E, #A4A47E 4px, transparent 4px);
}
.status-sold {
  color: #c15bc1;
  background: linear-gradient(to left, #A65AA6, #A65AA6 4px, transparent 4px);
}
.status-recruited {
  color: #bd5a51;
  background: linear-gradient(to left, #854c47, #854c47 4px, transparent 4px);
}
.status-filled {
  color: #337fcc;
  background: linear-gradient(to left, #337fcc, #337fcc 4px, transparent 4px);
}
.status-sealed {
  color: var(--ion-color-success);  /* brown b16158 */
  background: linear-gradient(to left, var(--ion-color-success), var(--ion-color-success) 4px, transparent 4px);
}
.status-invoiced {
  color: #1f623c;
  background: linear-gradient(to left, #1f623c, #1f623c 4px, transparent 4px);
}
.status-paid {
  color: var(--ion-color-success);
  background: linear-gradient(to left, var(--ion-color-success), var(--ion-color-success) 4px, transparent 4px);
}
.lineButtons div.MuiSelect-select {
  padding: 6px 14px;
}
.default-buttons {
  text-align: right;
}
.result-buttons {
  display: flex;
  align-items: center;
  background-color: #303030;
}
.modal-panel {
  background-color: #303030;
  padding: 10px 0 10px 10px;
}
.field-buttons {
  margin: 0 0 7px 0;
  padding-left: 3px;
  display: flex;
  align-items: center;
}
.result-buttons ion-label,
.field-buttons ion-label {
  padding: 0 3px 0 5px;
}
.result-buttons ion-toggle,
.field-buttons ion-toggle {
  padding: 3px;
}

.alternate-bg:nth-of-type(even) {
  background-color: rgba(140, 140, 140, 0.1);
}

div.sel-wider.rdg-cell[aria-selected=true] {
  z-index: 1;
  width: inherit;
  /* padding-left: 10px; */
}
div.rdg-row[aria-selected=true] {
  --row-selected-background-color: #1a2233;
  --row-selected-hover-background-color: #161d2c;
}

div.rdg-row[style*="--row-height:300px"] .rdg-row {
  --row-selected-background-color: #0C1823;
  --row-selected-hover-background-color: #0E1D29;
  --checkbox-color: #94cfff;
  --checkbox-focus-color: #c7e6ff;
}

div.rdg-row[style*="--row-height:300px"] {
  --selection-color: none;
}
.line-cell {
  background: #011414; /* #0C1823*/
}
.line-cell:hover {
  background: #021c1c; /* #0C1823*/
}
.line-cell .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  font-size: inherit;
  padding: 3px 2px;
}
.line-header {
  background: #111;
}
.rdg-cell input.MuiInputBase-input {
  font-size: var(--font-size);
  padding-left: 9px;
}
.rdg-cell .MuiMenuItem-root {
  font-size: inherit;
  padding: 7px 0;
  text-align: center;
  display: block;
}
.rdg-cell .MuiSelect-root {
  font-size: inherit;
  display: block;
}
.rdg-cell .MuiSelect-select {
  padding: 12px 8px;
  text-align: left;
}
.rdg-cell .MuiSelect-root .MuiSelect-icon {
  right: 0;
}
.rdg-cell .MuiAutocomplete-root MuiFormControl-root {
  vertical-align: middle;
}
.rdg-cell .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 0;
  padding-bottom: 0;
}
.rdg-cell .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rdg-cell .MuiOutlinedInput-root fieldset {
  border: 0;
}
div[role=tooltip] .MuiFormControl-root:not(.inplace-editor) {
  background-color: #3B3B3B;
}
.inplace-editor .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0;
}
.MuiCalendarPicker-root, [role=dialog] .MuiPaper-root > div {
  background-color: #123249;
}
.MuiTabs-root button {
  background-color: rgba(140, 140, 140, 0.1);
}
.MuiTabs-root button:hover {
  background-color: rgba(140, 140, 140, 0.2);
}
.MuiCalendarPicker-root {
  border-radius: 4px;
}

.MuiCalendarPicker-root .MuiButtonBase-root {
  line-height: normal;
}
div.rdg-row[style*="--row-height:300px"] > .rdg-cell:first-child {
  display: none;
}

.cell-expand-back div[role=grid] {
  background-color: #111;
}
div.rdg-row div.cell-expand-back {
  background-color: #111;
}
div.rdg-row:hover div.cell-expand-back {
  background-color: #000f0f;
}
.selectresult {
  background-color: var(--ion-color-step-150);
  border-radius: 5px;
}

/* end react-data-grid */

ion-button {
  text-transform: none;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #1b1b1b;
}
.container .text-right ion-select {
  float: right;
  padding-top: 9px;
  padding-bottom: 6px;
}
.container .text-right ion-select.year-select {
  width: 4.5em;
}
.container .text-right ion-select.month-select {
  width: 7.5em;
}
.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.align-right {
  text-align: right;
}
.obscure {
  color: rgba(255, 255, 255, .2);
}
.rdg-cell[role=columnheader] {
  text-align: center;
}

.rdg-row-even { background-color: #1B1B1B; }

.rdg-cell[role=columnheader][aria-selected=true],
.rdg-cell[role=gridcell][aria-colindex='1'][aria-selected=true] {
  box-shadow: none;
}
.rdg-cell {
  text-overflow: unset;
}
.fade:not([aria-selected=true]):after {
  content:'';
  width:1.1em;
  height:100%;
  position:absolute;
  right:0;
  top:0;
}
.rdg-row-even .fade:not([aria-selected=true]):after {
  background:linear-gradient(to right, transparent, #1B1B1B 70%);
}
.rdg-row-odd .fade:not([aria-selected=true]):after {
  background:linear-gradient(to right, transparent, var(--background-color) 70%); /* var(--background-color) */
}
.rdg-row:hover .fade:not([aria-selected=true]):after {
  background:linear-gradient(to right, transparent, var(--row-hover-background-color) 70%); /* var(--background-color) */
}
[aria-selected=true].rdg-row .fade:after {
  background:linear-gradient(to right, transparent, var(--row-selected-background-color) 70%);
}
[aria-selected=true].rdg-row:hover .fade:after {
  background:linear-gradient(to right, transparent, var(--row-selected-hover-background-color) 70%);
}

.container a {
  text-decoration: none;
}
.container > .rdg {
  flex: 1;
}
.lineButtons {
  display: flex;
  min-height: 6px;
}
.linegrid {
  margin-left: 87px;
}
.colButtons {
  display: flex;
  flex-direction: column;
  float: left;
  padding: 1px 1px 0;
  min-width: 35px;
}
.colButtons ion-button {
  margin-top: 3px;
  margin-bottom: 2px;
}
.colButtons .label {
  width: 34px;
}
.colButtons ion-icon {
  width: 20px;
}
.filter-cell {
  padding: 0;
}
.filter-cell > div > input {
  line-height: 1.5em;
}
.filter-cell > div {
  padding: 0 5px;
}
.filters {
  width: 100%;
  padding: 4px;
  font-size: 14px;
}

.react-datepicker-popper .react-datepicker {
  background-color: #1b1b1b;
  color: #efefef;
}
.react-datepicker-popper .react-datepicker__header {
  background-color: #1b1b1b;
}
.react-datepicker-popper .react-datepicker__day:hover {
  color: black;
}
.react-datepicker-popper .react-datepicker-year-header {
  color: #efefef;
}
.react-datepicker-popper .react-datepicker__day-name, .react-datepicker-popper .react-datepicker__day, .react-datepicker-popper .react-datepicker__time-name {
  color: #ccc;
}
.react-datepicker-popper .react-datepicker__day--selected {
  background-color: var(--ion-color-primary);
  color: white;
}

.react-datepicker-popper .react-datepicker__current-month {
  color: var(--ion-color-tertiary);
}

.cell-expand {
  float: right;
  display: table;
  height: 100%;

}
.cell-expand > span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}
.cell-expand > span span {
  outline: none;
}
.cell-expand-back {
  padding: 3px 10px 10px 0;
  margin-left: -36px;
}